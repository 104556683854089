import {defer, type LoaderArgs, type MetaFunction} from '@shopify/remix-oxygen';
import {Suspense} from 'react';
import {Await, useLoaderData, useMatches} from '@remix-run/react';
import {ProductSwimlane, Section, Skeleton} from '~/components';
import {PRODUCT_CARD_FRAGMENT} from '~/data/fragments';
import type {
  Article,
  ArticleConnection,
  Blog,
  Collection,
  ProductConnection,
} from '@shopify/hydrogen/storefront-api-types';
import {
  AnalyticsPageType,
  CacheLong,
  flattenConnection,
} from '@shopify/hydrogen';
import {SHOP_NAME} from '~/lib/const';
import {CollectionHero} from '~/components/collections/CollectionHero';
import InstagramFeed from '~/components/social/InstagramFeedSection';
import {Newsletter} from '~/components/newsletter/NewsletterSection';
import BlogSection from '~/components/blog/BlogSection';

// interface HomeSeoData {
//   shop: {
//     name: string;
//     description: string;
//   };
// }

// export interface CollectionHero {
//   byline: Metafield;
//   cta: Metafield;
//   handle: string;
//   heading: Metafield;
//   height?: 'full';
//   loading?: 'eager' | 'lazy';
//   spread: Metafield;
//   spreadSecondary: Metafield;
//   top?: boolean;
// }

export const handle = {
  seo: {
    title: 'Home page',
  },
};

//Instead of using the standard meta we use the shopify handle.seo:{}
// export const meta: MetaFunction = () => {
//   return {
//     title: 'All Collections',
//   };
// };

export async function loader({context}: LoaderArgs) {
  const {language, country} = context.storefront.i18n;

  const variablesByShopName =
    SHOP_NAME === 'kocca'
      ? {
          heroCollectionHandle: 'hero-collection-kocca',
          productQuery: 'tag:Abbigliamento -> Capispalla',
          homeLeftCollection: 'home-left-collection-kocca',
          homeCenterCollection: 'home-center-collection-kocca',
          homeRightCollection: 'home-right-collection-kocca',
          highlightedCollection: 'home-higlight-collection-kocca',
          blogHandle: 'storie',
        }
      : {
          heroCollectionHandle: 'vans',
          productQuery: '',
          homeLeftCollection: 'vans',
          homeCenterCollection: 'nike',
          homeRightCollection: 'converse',
          highlightedCollection: 'vans',
          blogHandle: 'news',
        };

  const {heroCollection} = await context.storefront.query<{
    heroCollection: Collection;
  }>(HERO_COLLECTION_QUERY, {
    variables: {heroCollectionHandle: variablesByShopName.heroCollectionHandle},
    cache: context.storefront.CacheLong(),
  });

  const fetchInstaFeed = async () => {
    const {storefront, waitUntil} = context;
    const instagramUrl = `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=4&access_token=${context.env.PUBLIC_INSTAGRAM_ACCESS_TOKEN}`;
    let instagramGraphResponse;

    // Check if there's a match for this key.
    let cachedResponse = await storefront.cache.match(instagramUrl);
    if (!cachedResponse) {
      // Since there's no match, fetch a fresh response.
      const response = await fetch(instagramUrl);
      instagramGraphResponse = await response.json();

      // Create a new response with the same body as the original, but with a
      // cache-control. new Response(response.body) is not working i've to convert to string
      cachedResponse = new Response(JSON.stringify(instagramGraphResponse), {
        status: 200,
        headers: {
          'cache-control': 'public, max-age=3600',
        },
      });
      // Store the response in cache to be re-used the next time.
      waitUntil(storefront.cache.put(instagramUrl, cachedResponse));
    } else {
      instagramGraphResponse = await cachedResponse.json();
    }

    const data = instagramGraphResponse.data.map((edge: any) => ({
      id: edge.id,
      mediaContentType:
        edge.media_type === 'IMAGE'
          ? 'MediaImage' //MediaContentType.Image
          : 'ExternalVideo', //MediaContentType.ExternalVideo,
      width: 1,
      height: 1,
      url: edge.media_url,
      alt: edge.caption,
    }));
    return data;
  };

  return defer({
    heroCollection,
    featuredProducts: context.storefront.query<{
      products: ProductConnection;
    }>(FEATURED_PRODUCTS_QUERY, {
      variables: {
        productQuery: variablesByShopName.productQuery,
        country,
        language,
      },
      cache: context.storefront.CacheLong(),
    }),

    highlightedCollections: context.storefront.query<{
      homeLeftCollection: Collection;
      homeCenterCollection: Collection;
      homeRightCollection: Collection;
    }>(HIGHLIGHTED_COLLECTIONS_QUERY, {
      variables: {
        homeLeftCollection: variablesByShopName.homeLeftCollection,
        homeCenterCollection: variablesByShopName.homeCenterCollection,
        homeRightCollection: variablesByShopName.homeRightCollection,
        country,
        language,
      },
      cache: context.storefront.CacheLong(),
    }),

    highlightedCollection: context.storefront.query<{
      collection: Collection;
    }>(HIGHLIGHTED_COLLECTION_QUERY, {
      variables: {
        collectionHandle: variablesByShopName.highlightedCollection,
        country,
        language,
      },
      cache: context.storefront.CacheLong(),
    }),
    // fetchInstaFeed: fetchInstaFeed(),
    fetchBlogArticles: context.storefront.query<{
      blogArticles: Blog;
    }>(BLOG_ARTICLES_QUERY, {
      variables: {
        blogHandle: variablesByShopName.blogHandle.toLowerCase(),
      },
      cache: context.storefront.CacheLong(),
    }),

    randomNumber: Math.random(),

    blogHandle: variablesByShopName.blogHandle.toLowerCase(),

    analytics: {
      pageType: AnalyticsPageType.home,
    },
  });
}

export default function Homepage() {
  const {
    heroCollection,
    featuredProducts,
    highlightedCollections,
    highlightedCollection,
    // fetchInstaFeed,
    fetchBlogArticles,
    randomNumber,
    blogHandle,
  } = useLoaderData<typeof loader>();

  // TODO: analytics
  // useServerAnalytics({
  //   shopify: {
  //     pageType: ShopifyAnalyticsConstants.pageType.home,
  //   },
  // });
  return (
    <>
      <CollectionHero
        collection={heroCollection}
        type="banner"
      ></CollectionHero>
      {/* {randomNumber} */}

      {featuredProducts && (
        <Suspense>
          <Await resolve={featuredProducts}>
            {({products}) => {
              if (!products?.nodes) return <></>;
              return (
                <ProductSwimlane
                  products={products.nodes}
                  title="Featured Products"
                  count={4}
                  divider="bottom"
                />
              );
            }}
          </Await>
        </Suspense>
      )}

      {highlightedCollections && (
        <Suspense>
          <Await resolve={highlightedCollections}>
            {({
              homeLeftCollection,
              homeCenterCollection,
              homeRightCollection,
            }) => {
              return (
                <div className="w-full overflow-x-auto snap-x snap-mandatory">
                  <div className="flex w-fit lg:w-full min-w-fit flex-nowrap py-6 md:py-8 lg:py-12">
                    <div className="flex-grow w-[calc(100vw_-_24px)] lg:w-full snap-center">
                      <CollectionHero
                        collection={homeLeftCollection}
                        type="card"
                      ></CollectionHero>
                    </div>
                    <div className="flex-grow w-[calc(100vw_-_24px)] lg:w-full snap-center">
                      <CollectionHero
                        collection={homeCenterCollection}
                        type="card"
                      ></CollectionHero>
                    </div>
                    <div className="flex-grow w-[calc(100vw_-_24px)] lg:w-full snap-center">
                      <CollectionHero
                        collection={homeRightCollection}
                        type="card"
                      ></CollectionHero>
                    </div>
                  </div>
                </div>
              );
            }}
          </Await>
        </Suspense>
      )}

      {highlightedCollection && (
        <Suspense>
          <Await resolve={highlightedCollection}>
            {({collection}) => {
              if (!collection) return <></>;
              return (
                <Section padding="y">
                  <CollectionHero
                    collection={collection}
                    type="textWithImage"
                  ></CollectionHero>
                </Section>
              );
            }}
          </Await>
        </Suspense>
      )}

      {/* <Suspense fallback="insta skeleton">
        <Await resolve={fetchInstaFeed}>
          {(feed) => {
            return <InstagramFeed feed={feed}></InstagramFeed>;
          }}
        </Await>
      </Suspense> */}
      {/* <InstagramFeed></InstagramFeed> */}

      <Suspense fallback="blog skeleton">
        <Await resolve={fetchBlogArticles}>
          {({blogArticles}) => {
            const blogArticlesFlat: Article[] =
              flattenConnection<ArticleConnection>(blogArticles.articles);
            return (
              <BlogSection
                blogArticles={blogArticlesFlat}
                blogHandle={blogHandle}
              ></BlogSection>
            );
          }}
        </Await>
      </Suspense>

      <Newsletter></Newsletter>
    </>
  );
}

const HERO_COLLECTION_QUERY = `#graphql
  query homepage(
    $country: CountryCode
    $language: LanguageCode
    $heroCollectionHandle: String
  ) @inContext(country: $country, language: $language) {
    heroCollection: collection(handle: $heroCollectionHandle) {
      handle
      title
      description
      image {
        url
      }
      metafields(identifiers: [{namespace: "bitforce", key: "mobile_media"}]) {
        id
        type
        key
        reference {
          __typename
          ... on MediaImage {
            image {
              url
              altText
            }
          }
        }
      }
    }
  }
`;

export const FEATURED_PRODUCTS_QUERY = `#graphql
  ${PRODUCT_CARD_FRAGMENT}
  query homepageFeaturedProducts($country: CountryCode, $language: LanguageCode, $productQuery: String)
  @inContext(country: $country, language: $language) {
    products(first: 8, query: $productQuery) {
      nodes {
        ...ProductCard
      }
    }
  }
`;

export const HIGHLIGHTED_COLLECTIONS_QUERY = `#graphql
  query homepage(
    $country: CountryCode
    $language: LanguageCode
    $homeLeftCollection: String
    $homeCenterCollection: String
    $homeRightCollection: String
  ) @inContext(country: $country, language: $language) {
    homeLeftCollection: collection(handle: $homeLeftCollection) {
      handle
      title
      descriptionHtml
      image {
        url
      }
    }
    homeCenterCollection: collection(handle: $homeCenterCollection) {
      handle
      title
      descriptionHtml
      image {
        url
      }
    }
    homeRightCollection: collection(handle: $homeRightCollection) {
      handle
      title
      descriptionHtml
      image {
        url
      }
    }
  }
`;

export const HIGHLIGHTED_COLLECTION_QUERY = `#graphql
  query homepage(
    $country: CountryCode
    $language: LanguageCode
    $collectionHandle: String
  ) @inContext(country: $country, language: $language) {
    collection: collection(handle: $collectionHandle) {
      handle
      title
      descriptionHtml
      image {
        url
      }
    }
  }
`;

export const BLOG_ARTICLES_QUERY = `#graphql
  query homepage(
    $country: CountryCode
    $language: LanguageCode
    $blogHandle: String
  ) @inContext(country: $country, language: $language) {
    blogArticles: blog(handle: $blogHandle) {
      articles(first: 3) {
        edges {
          node {
            author: authorV2 {
              name
            }
            excerptHtml
            contentHtml
            handle
            id
            tags
            image {
              id
              altText
              url
              width
              height
            }
            publishedAt
            title
          }
        }
      }
    }
  }
`;

// const COLLECTION_CONTENT_FRAGMENT = `#graphql
//   ${MEDIA_FRAGMENT}
//   fragment CollectionContent on Collection {
//     id
//     handle
//     title
//     descriptionHtml
//     heading: metafield(namespace: "hero", key: "title") {
//       value
//     }
//     byline: metafield(namespace: "hero", key: "byline") {
//       value
//     }
//     cta: metafield(namespace: "hero", key: "cta") {
//       value
//     }
//     spread: metafield(namespace: "hero", key: "spread") {
//       reference {
//         ...Media
//       }
//     }
//     spreadSecondary: metafield(namespace: "hero", key: "spread_secondary") {
//       reference {
//         ...Media
//       }
//     }
//   }
// `;
