import {Image} from '@shopify/hydrogen';
import {SRC_SET_SIZES} from '~/lib/const';
import {getSrcSetSizes} from '~/lib/utils';
import {Button} from '~/components';

export function Newsletter() {
  return (
    <div className="p-12 py-24 relative w-full h-full z-10">
      <Image
        data={{
          url: 'https://cdn.shopify.com/s/files/1/0551/5290/2227/files/newsletter.webp?v=1672238763',
        }}
        alt={'Newsletter'}
        widths={SRC_SET_SIZES}
        sizes={getSrcSetSizes([100, 90, 90, 90, 90, 90])}
        loaderOptions={{crop: 'center', width: 16, height: 9}}
        className="absolute z-10 inset-0 w-full h-full object-cover aspect-[16/9]"
      />

      <div className="absolute z-10 inset-0 w-full h-full bg-white bg-opacity-30"></div>

      <div className="relative grid grid-cols-1 md:grid-cols-2 z-20">
        <div className="md:col-start-2">
          <h3>JOIN KOCCA</h3>
          <h5>Ricevi promozioni esclusive e resta sempre aggiornata</h5>

          <form className="block mt-16">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <input
                  type="text"
                  placeholder="Nome"
                  className="w-full"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Cognome"
                  className="w-full"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  className="w-full"
                  required
                />
              </div>
              <div>
                <input
                  type="date"
                  placeholder="Data di nascita"
                  className="w-full"
                  required
                />
              </div>

              <div className="col-span-2 mt-8">
                <span className="font-bold block">
                  Trattamento dei dati personali
                </span>
                <div className="flex items-center mt-4">
                  <input
                    type="checkbox"
                    name="privacy"
                    id="privacy"
                    className="w-6 h-6"
                  />
                  <label htmlFor="privacy" className="ml-4 cursor-pointer">
                    Ho preso visione dell'informativa sulla Privacy Policy
                    redatta ai sensi degli art. 13 e 14 del Regolamento Europeo
                    679/2016 ed esprimo il mio consenso al trattamento dei miei
                    dati personali per finalità commerciali da parte di Kocca,
                    tra cui invio di comunicazioni marketing via newsletter e/o
                    sms.
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-8 w-full">
              <Button type="submit" variant="secondary" className="uppercase">
                ISCRIVITI!
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
