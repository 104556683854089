import {Article} from '@shopify/hydrogen/storefront-api-types';
import {Button, Grid, Link, Section} from '~/components';
import {BlogArticleCard} from './BlogArticleCard';

export default function BlogSection({
  blogArticles,
  blogHandle,
}: {
  blogArticles: Article[];
  blogHandle: string;
}) {
  return (
    <Section padding="all">
      <div className="text-center">
        <span className="block mb-4">Blog</span>
        <h1>LASCIATI ISPIRARE DALLE NOSTRE STORIE</h1>
      </div>
      <Grid as="ol" layout="blog" gap="blog">
        {blogArticles.map((article, i) => {
          return (
            <BlogArticleCard
              blogHandle={blogHandle}
              article={article}
              key={article.id}
              // loading={getImageLoadingPriority(i, 2)}
            />
          );
        })}
      </Grid>

      <div className="flex justify-center mt-16">
        <Button as={Link} to={`/blog/${blogHandle}`}>
          LEGGI TUTTO
        </Button>
      </div>
    </Section>
  );
}
